import { useContext, createContext, useState, useEffect } from "react";
import axios from "axios";

// Creating a context for authentication
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// Auth Provider to wrap around components
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Simulate user persistence (e.g., from sessionStorage)
  useEffect(() => {
    const storedUser = JSON.parse(sessionStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser);
    }
    setLoading(false);
  }, []);

  // Login function using Axios with username and password
  const login = async (username, password) => {
    setError(null);
    try {
      // Simulating an API request
      const response = await axios.post(
        "https://jsonplaceholder.typicode.com/users", 
        { username, password }
      );
      
      // Check if user exists and credentials match (mock logic)
      if (response.data && response.data.username === username) {
        const userData = response.data;
        setUser(userData);
        sessionStorage.setItem("user", JSON.stringify(userData)); // Store in sessionStorage
      } else {
        setError("Invalid username or password");
      }
    } catch (err) {
      setError("An error occurred during login. Please try again later.");
    }
  };

  // Logout function
  const logout = () => {
    setUser(null);
    sessionStorage.removeItem("user"); // Remove from sessionStorage
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading, error }}>
      {children}
    </AuthContext.Provider>
  );
};
